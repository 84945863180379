import {tracking, trackingKeys} from "@/modules/tracking/tracking.index";
import {debounce, isMobile} from "@/pages/search/helpers";

export type FeaturedAgentTrackingData = {
  position: string,
  tenure: string,
  location_1: string,
  location_2: string,
  location_3: string,
  type: string,
  company_id: string,
  user_id: string,
  featured_label: string,
  level: string,
}

type PropertyCardData = {
  id: number;
  lat: number;
  lng: number;
}
type TypeListing = 'property' | 'project'
/**
 * Property card have 3 events
 * 1. trackingClick: This event is triggered when user clicks on the property card
 * 2. hoverOnPropertyCard(true): This event is triggered when user hovers on the property card
 * 3. hoverOnPropertyCard(false): Close card popup when user hovers out of the property card
 * */
export default (
  position = '',
  tenure = '',
  locationSlug = '',
  type = '',
  company_id = '',
  user_id = '',
  featured_label = '',
  level = '',
  property: PropertyCardData
) => ({
  /**
   *  Tracking click event
   * */
  trackingClick: () => {
    if (!position.length) {
      if (featured_label?.includes("featured_seller")) {
        position = "search_featured_agent";
      } else if (featured_label?.includes("featured_listing")) {
        position = "search_featured";
      } else if (level === "standard") {
        position = "search_standard";
      }
    }

    // location 1, 2, 3 is locationSlug exploded by '-'
    const [location_1, location_2, location_3] = locationSlug.split('/')
      .map((loc) => loc?.replace(/-/g, " ") ?? '') ?? '';

    const data: FeaturedAgentTrackingData = {
      position: position,
      tenure: tenure,
      location_1: location_1,
      location_2: location_2,
      location_3: location_3,
      type: type,
      company_id: company_id,
      user_id: user_id,
      featured_label: featured_label,
      level: level,
    }
    tracking(
      "property_card_click",
      data as FeaturedAgentTrackingData,
      trackingKeys.click
    )
  },
  /**
   *  Hover and Out events
   * */
  onHoverPropertyCard(onHover: boolean = true, typeListing: TypeListing = 'property', projectID: string = '') {
    // popup property card only in desktop
    if (!isMobile() && property) {
      debounceHoverOnPropertyCard(property, onHover, typeListing, projectID);
    }
  },
});

const debounceHoverOnPropertyCard = debounce(hoverOnPropertyCard, 300);
let abortController: AbortController | undefined = undefined

async function hoverOnPropertyCard(
  property: PropertyCardData,
  onHover: boolean = true,
  typeListing: TypeListing,
  projectID: string = ''
) {
  let module = await import("@/pages/search/modules/module.map")
  if (onHover) {
    // abort previous request if exist
    if (abortController) {
      abortController.abort()
    }
    abortController = new AbortController()
    try {
      if ('property' === typeListing) {
        // Get property card template
        module.fetchCardTemplate([property.id], abortController).then(res => {
          if (res.data.length > 0) module.mapPopHover(property.lat, property.lng, res, property.id, projectID);
        });
      } else {
        // Get project card template
        module.fetchCardProjectTemplate(property.id, abortController).then(res => {
          if (res.data.length > 0) module.mapPopHover(property.lat, property.lng, res, property.id);
        });
      }
    } catch (error) {
      console.log('fetchPropertyCard error: ', error)
    }
  } else {
    module.closePopupHoverProperty();
  }
}


